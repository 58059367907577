import React, {useState, useEffect} from "react";
import { AiOutlineLogin, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import highVoltage from '../Assests/eTSTImg/high-voltage.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import stats from '../Assests/eTSTImg/stats.png';
import mark from '../Assests/eTSTImg/complete.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import wallet from '../Assests/eTSTImg/wallet.png';
import logout from '../Assests/eTSTImg/logout.png';
import { useSelector, useDispatch } from "react-redux";
//import MinerTaskCard from '../components/Route/Mine/MinerTaskCard';
//import { getAllMinerTasks, setRminerId } from '../redux/actions/minertask';
//import { selectFilteredMinerTasks } from '../redux/minertaskSelectors'


const MinerTasks = () => {
 //const dispatch = useDispatch();
  const { minertasks } = useSelector((state) => state.minertask); 
  const { rminer } = useSelector((state) => state.miner);
  
  
const theMiner = rminer && rminer._id;

console.log(minertasks);


  return (
      <div
          className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
          style={{
              background: "linear-gradient(#000, #350101, rgb(158, 148, 3))",
          }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white pt-6 overflow-y-scroll">
              <div className="w-full overflow-y-scroll">
              {minertasks && minertasks.length > 0 ? (
                    <>
                        {minertasks && minertasks.map((mina) => (

<div key={mina._id}>
<Link to={`/app.tst-token/minertask/${mina._id}`}>
<div className="flex items-start text-sm font-[300] mt-2">
<div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-4 rounded-2xl flex">
    <button className="flex items-center">
    <img src={coin} width={30} height={30} alt="" />
    </button>
    <button className="flex flex-col items-start gap-1 ml-4">
    <div className="mt-3 text-2xl font-bold flex items-start">
    <img src={tasks} width={20} height={20} />
    <span className="ml-2">{mina.taskReward}</span>
    </div>
      <span className="text-gray-300">{mina.taskTitle}</span>
    </button>
    <button className="flex items-center">
    {mina.taskers.some(tasker => tasker.taskerID === theMiner) && <img className="ml-5" src={mark} width={20} height={20} />}
    </button>
</div>
</div>
</Link>
</div>
                        ))}
                      </>
                  ) : (
                      <div className="w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
                          <div className="text-base mt-1 flex items-center">
                              <h5 className="font-[400]">No Task Found!</h5>
                          </div>
                          <div className="relative mt-6">
                              <img src={tasks} width={150} height={150} alt="etst" />
                          </div>
                      </div>
                  )}
              </div>

              <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
                  <div className="w-full flex justify-between gap-2 mt-4">
                      <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
                          <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
                              <Link to="/app.tst-token/miner/earn">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={coin} width={24} height={24} alt="Earn" />
                                      <span className="text-gray-300">Earn</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <button className="flex flex-col items-center gap-1">
                                  {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                                  <span className="text-[#c89b2a] font-[500]">Tasks</span>
                              </button>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/friends">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={bear} width={24} height={24} alt="Friends" />
                                      <span className="text-gray-300">Friends</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/boost">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={turbo} width={24} height={24} alt="Boosts" />
                                      <span className="text-gray-300">Boosts</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/stats">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={stats} width={24} height={24} alt="Stats" />
                                      <span className="text-gray-300">Stats</span>
                                  </button>
                              </Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

const MinerTasksPage = () => {
  return (
      <>
          <MinerTasks />
      </>
  );
};

export default MinerTasksPage;


/*

import React, {useState, useEffect} from "react";
import { AiOutlineLogin, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import highVoltage from '../Assests/eTSTImg/high-voltage.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import stats from '../Assests/eTSTImg/stats.png';
import mark from '../Assests/eTSTImg/complete.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import wallet from '../Assests/eTSTImg/wallet.png';
import logout from '../Assests/eTSTImg/logout.png';
import { useSelector, useDispatch } from "react-redux";
//import MinerTaskCard from '../components/Route/Mine/MinerTaskCard';
import { getAllMinerTasks, setRminerId } from '../redux/actions/minertask';
import { selectFilteredMinerTasks } from '../redux/minertaskSelectors'


const MinerTasks = () => {
  const dispatch = useDispatch();
  const { rminer } = useSelector((state) => state.miner);
  
  // Set rminerId in Redux when component mounts or rminer changes
  useEffect(() => {
    if (rminer && rminer._id) {
        dispatch(setRminerId(rminer._id));
    }
}, [dispatch, rminer]);

// Fetch all miner tasks
    useEffect(() => {
    dispatch(getAllMinerTasks());
}, [dispatch]);

const filteredMinertasks = useSelector(selectFilteredMinerTasks);

const theMiner = rminer && rminer._id;


  return (
      <div
          className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
          style={{
              background: "linear-gradient(#000, #350101, rgb(158, 148, 3))",
          }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white pt-6">
              <div className="w-full overflow-y-scroll">
              {filteredMinertasks && filteredMinertasks.length > 0 ? (
                    <>
                        {filteredMinertasks && filteredMinertasks.map((mina) => (

<div key={mina._id}>
<Link to={`/app.tst-token/minertask/${mina._id}`}>
<div className="flex items-start text-sm font-[300] mt-2">
<div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-4 rounded-2xl flex">
    <button className="flex items-center">
    <img src={coin} width={30} height={30} alt="" />
    </button>
    <button className="flex flex-col items-start gap-1 ml-4">
    <div className="mt-3 text-2xl font-bold flex items-start">
    <img src={tasks} width={20} height={20} />
    <span className="ml-2">{mina.taskReward}</span>
    </div>
      <span className="text-gray-300">{mina.taskTitle}</span>
    </button>
    <button className="flex items-center">
    {mina.taskers.includes(theMiner) && <img className="ml-5" src={mark} width={20} height={20} />}
    </button>
</div>
</div>
</Link>
</div>
                        ))}
                      </>
                  ) : (
                      <div className="w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
                          <div className="text-base mt-1 flex items-center">
                              <h5 className="font-[400]">No Task Found!</h5>
                          </div>
                          <div className="relative mt-6">
                              <img src={tasks} width={150} height={150} alt="etst" />
                          </div>
                      </div>
                  )}
              </div>

              <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
                  <div className="w-full flex justify-between gap-2 mt-4">
                      <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
                          <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
                              <Link to="/app.tst-token/miner/earn">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={coin} width={24} height={24} alt="Earn" />
                                      <span className="text-gray-300">Earn</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <button className="flex flex-col items-center gap-1">
                                  {filteredMinertasks && filteredMinertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                                  <span className="text-[#c89b2a] font-[500]">Tasks</span>
                              </button>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/friends">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={bear} width={24} height={24} alt="Friends" />
                                      <span className="text-gray-300">Friends</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/boost">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={turbo} width={24} height={24} alt="Boosts" />
                                      <span className="text-gray-300">Boosts</span>
                                  </button>
                              </Link>
                              <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                              <Link to="/app.tst-token/miner/stats">
                                  <button className="flex flex-col items-center gap-1">
                                      <img src={stats} width={24} height={24} alt="Stats" />
                                      <span className="text-gray-300">Stats</span>
                                  </button>
                              </Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

const MinerTasksPage = () => {
  return (
      <>
          <MinerTasks />
      </>
  );
};

export default MinerTasksPage;
 */