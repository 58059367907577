import { configureStore } from "@reduxjs/toolkit";

import { minertaskReducer } from "./reducers/minertask";
import { minerReducer } from "./reducers/miner";



const Store = configureStore({
  reducer: {
    minertask: minertaskReducer,
    miner: minerReducer,
  },
});

export default Store;
