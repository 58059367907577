import React from 'react';
import logo from '../Assests/eTSTImg/etstcoin.png'
import "./flippingImage.css";

const FlippingImage = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="animate-flip">
        <img 
          src={logo} 
          alt="" 
          className="w-40 h-40 object-cover"
        />
      </div>
    </div>
  );
};

export default FlippingImage;