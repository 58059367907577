import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    isLoading: true,
    minertasks: [],
    rminerId: null, // Added field for rminer ID
};

export const minertaskReducer = createReducer(initialState, {
    // Action to set the current rminer ID
    setRminerId: (state, action) => {
        state.rminerId = action.payload;
    },

    // Load all minertasks
    getAllMinerTasksRequest: (state) => {
        state.isLoading = true;
    },

    getAllMinerTasksSuccess: (state, action) => {
        state.isLoading = false;
        state.minertasks = action.payload;
    },

    getAllMinerTasksFailed: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },

    // Load single miner task
    LoadminertaskRequest: (state) => {
        state.isLoading = true;
    },

    LoadminertaskSuccess: (state, action) => {
        state.isLoading = false;
        state.minertask = action.payload;
    },

    LoadminertaskFail: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },

    clearErrors: (state) => {
        state.error = null;
    },
});
