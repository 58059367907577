import React, { useState } from 'react'
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import BackgroundAnimate from '../BackgroundAnimate';
import coin from '../Assests/eTSTImg/coin.png';
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { isVisible } from "@testing-library/user-event/dist/utils";
//import Header from "../components/Layout/Header";
import FlippingImage from "../components/FlippingImage";


const HomePage = ({ className, ...rest }) => {

  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <div>
      <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium">

          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
          
          <div className="mt-4 text-4xl font-bold flex items-center">
          <h5 data-number="500000000000">
          <CountUp {...rest} start={viewPortEntered ? null : 0} end={500000000000} duration={5} delay={0}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={() => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
          </h5>
            </div>
             <div className="text-base mt-1 flex items-center">
            <h5 className="font-[300]">Maximum eTST Supply</h5>
            </div>
            <br />
            <br />
           

                             

          <div className="relative mt-5">
            <FlippingImage />
            </div>
            <br />
            <br />

          

          <div className="text-base mt-4 flex items-center">
          <h5 className="font-[300] ml-2 text-center">Be part of the next big project coming to the TON Blockchain</h5>
          </div>



          <div className="mt-4 text-3xl font-bold flex items-center">
          <Link to="/app.tst-token/white-paper">
          <div className={`${styles.buttonRed} !bg-[#a000c8]`}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               White Paper
               </span>
          </div>
          </Link>
          </div>

          <div className="mt-4 text-3xl font-bold flex items-center">
          <Link to="/app.tst-token/miner/earn">
          <div className={`${styles.buttonRed} !bg-[teal]`}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Enter Mine
               </span>
          </div>
          </Link>
          </div>

          
          <div className="text-base mt-4 flex items-center">
          <img src={coin} width={20} height={20} />
          </div>
        </div>



        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-center items-center mt-4">
           <h5>eTST... endless possibilities.</h5>
          </div>
        </div>

        </div>


        </div>
       <BackgroundAnimate />
    </div>
  )
}

export default HomePage
