import "./backgroundanimate.css";


const BackgroundAnimate = () => {
    return (
      <ul className="background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    )
  }
  
  export default BackgroundAnimate