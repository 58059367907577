import React from 'react'
import LoginMiner from "../components/Login/LoginMiner";

const LoginMinerPage = () => {
    
  return (
    <div>
        <LoginMiner />
    </div>
  )
}

export default LoginMinerPage;