import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import stats from '../Assests/eTSTImg/stats.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import etstmain2 from '../Assests/eTSTImg/etstcoin2.png';

import bronze from '../Assests/eTSTImg/lbronze.png';
import silver from '../Assests/eTSTImg/lsilver.png';
import gold from '../Assests/eTSTImg/lgold.png';
import platinum from '../Assests/eTSTImg/lplatinum.png';

import { useSelector } from "react-redux";


const MinerLeague = () => {

    const { minertasks } = useSelector((state) => state.minertask);  
    const { rminer } = useSelector((state) => state.miner);   
            
    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">

          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">

          <div className="w-full flex justify-between gap-2 mt-2">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className={`w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex ${rminer?.leagueLevel === 1 && "border-solid border-[#fff] border-[1px]"}`}>
                <button className="flex items-center">
                <img src={trophy} width={50} height={50} alt="" />
                </button>
                <button className="flex flex-col items-center gap-1 ml-2">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">10,000</span>
                </div>
                  <span className="text-gray-300">Starter from 0 - 999,999</span>
                </button>
          </div>
          </div>
          </div>


          <div className="w-full flex justify-between gap-2 mt-4">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className={`w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex ${rminer?.leagueLevel === 2 && "border-solid border-[#fff] border-[1px]"}`}>
                <button className="flex items-center">
                <img src={bronze} width={50} height={50} alt="" />
                </button>
                <button className="flex flex-col items-center gap-1 ml-2">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">1,000,000</span>
                </div>
                  <span className="text-gray-300">Bronze from 1,000,000</span>
                </button>
          </div>
          </div>
          </div>

          <div className="w-full flex justify-between gap-2 mt-4">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className={`w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex ${rminer?.leagueLevel === 3 && "border-solid border-[#fff] border-[1px]"}`}>
                <button className="flex items-center">
                <img src={silver} width={50} height={50} alt="" />
                </button>
                <button className="flex flex-col items-center gap-1 ml-2">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">2,000,000</span>
                </div>
                  <span className="text-gray-300">Silver from 5,000,000</span>
                </button>
          </div>
          </div>
          </div>

          <div className="w-full flex justify-between gap-2 mt-4">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className={`w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex ${rminer?.leagueLevel === 4 && "border-solid border-[#fff] border-[1px]"}`}>
                <button className="flex items-center">
                <img src={gold} width={50} height={50} alt="" />
                </button>
                <button className="flex flex-col items-center gap-1 ml-2">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">3,000,000</span>
                </div>
                  <span className="text-gray-300">Gold from 10,000,000</span>
                </button>
          </div>
          </div>
          </div>

          <div className="w-full flex justify-between gap-2 mt-4">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className={`w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex ${rminer?.leagueLevel === 5 && "border-solid border-[#fff] border-[1px]"}`}>
                <button className="flex items-center">
                <img src={platinum} width={50} height={50} alt="" />
                </button>
                <button className="flex flex-col items-center gap-1 ml-2">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">5,000,000</span>
                </div>
                  <span className="text-gray-300">Platinum from 20,000,000</span>
                </button>
          </div>
          </div>
          </div>


          <div className="relative mt-4">
            <img src={etstmain2} width={100} height={100} alt="etst" />
            </div>
          
          
          </div>

        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-gray-300">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats">
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
        
    )
};


const MinerLeaguePage = () => {
    return (
        <>
        <MinerLeague />
        </>
    )
}

export default MinerLeaguePage;
