import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  MinerFriendsPage,
  //SignUpMinerPage,
  MinerAccountPage,
  MinerTasksPage,
  MinerBoostsPage,
  MinerStatsPage,
  MinerLeaguePage,
  //SignUpMinerRefPage,
  MinerTaskDetailPage,
  MineWhitePaperPage,
  //AuthPage,
  LoginMinerPage,
  MinerGuidePage,
  MGuide2Page,
 } from "./routes/Routes.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadMinerTask } from "./redux/actions/minertask";
import { loadMiner } from "./redux/actions/miner";
import ProtectedRoute from "./routes/ProtectedRoute";
import { getAllMinerTasks } from "./redux/actions/minertask";
//import axios from "axios";
//import { server } from "./server";



const App = () => {
  
    
  useEffect(() => {
    Store.dispatch(loadMiner());
    Store.dispatch(loadMinerTask());
    Store.dispatch(getAllMinerTasks());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        
        	
        {/* miner Routes */}

        <Route path="/mine" element={<LoginMinerPage />} />
        
        <Route 
        path="/app.tst-token/miner/earn" 
        element={
        //<ProtectedMinerRoute>
          <MinerAccountPage />
        //</ProtectedMinerRoute>
        } 
        />

        <Route path="/app.tst-token/mine/guide" element={<MinerGuidePage />} />
        <Route path="/app.tst-token/mine/guide2" element={<MGuide2Page />} />
        <Route path="/app.tst-token/miner/friends" element={<MinerFriendsPage />} />
        <Route path="/app.tst-token/miner/tasks" element={<MinerTasksPage />} />
        <Route path="/app.tst-token/miner/boost" element={<MinerBoostsPage />} />
        <Route path="/app.tst-token/miner/stats" element={<MinerStatsPage />} />
        <Route path="/app.tst-token/miner/league" element={<MinerLeaguePage />} />
        <Route path="/app.tst-token/minertask/:id" element={<MinerTaskDetailPage />} />
        <Route path="/app.tst-token/white-paper" element={<MineWhitePaperPage />} />

        
        
      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;


