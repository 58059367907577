import React, {useState, useEffect} from "react";
import { AiOutlineLogin, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import highVoltage from '../Assests/eTSTImg/high-voltage.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import stats from '../Assests/eTSTImg/stats.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import wallet from '../Assests/eTSTImg/wallet.png';
import tapbot from '../Assests/eTSTImg/tapbot.png';
import logout from '../Assests/eTSTImg/logout.png';
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";


const MinerBoosts = () => {

    const { minertasks } = useSelector((state) => state.minertask); 
    const { rminer } = useSelector((state) => state.miner);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const navigate = useNavigate();

    const bal = rminer?.totalTokenCollected;
    
     

      const boostHandler = async () => {
      const moneyToDeduct = 300000;
      const minerPayingId = rminer._id;

      if(moneyToDeduct > bal){
        return toast.error("Insuficient tokens");
      }
    
      await axios
      
            .put(
              `${server}/miner/boosting/${minerPayingId}`,
              {
                moneyToDeduct: moneyToDeduct,
              },
              { withCredentials: true }
            )
            .then((res) => {
              setOpen(false);
              toast.success("Boost Successful!")
              navigate("/app.tst-token/miner/earn");
              window.location.reload();
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });    
      }

      const boostHandler2 = async () => {
        const moneyToDeduct = 500000;
        const minerPayingId = rminer._id;

        if(moneyToDeduct > bal){
          return toast.error("Insuficient tokens");
        }
      
        await axios
        
              .put(
                `${server}/miner/boosting2/${minerPayingId}`,
                {
                  moneyToDeduct: moneyToDeduct,
                },
                { withCredentials: true }
              )
              .then((res) => {
                setOpen2(false);
                toast.success("Boost Successful!")
                navigate("/app.tst-token/miner/earn");
                window.location.reload();
              })
              .catch((error) => {
                toast.error(error.response.data.message);
              });    
        }


        const boostHandler3 = async () => {
          const moneyToDeduct = 500000;
          const minerPayingId = rminer._id;

          if(moneyToDeduct > bal){
            return toast.error("Insuficient tokens");
          }
        
          await axios
          
                .put(
                  `${server}/miner/boosting3/${minerPayingId}`,
                  {
                    moneyToDeduct: moneyToDeduct,
                  },
                  { withCredentials: true }
                )
                .then((res) => {
                  setOpen3(false);
                  toast.success("Boost Successful!")
                  navigate("/app.tst-token/miner/earn");
                  window.location.reload();
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                });    
          }
    
    
    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">

          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">

          <div className="w-full flex justify-between gap-2 mt-2">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex">
                <button className="flex items-center">
                <img src={rocket} width={30} height={30} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">2,000,000</span>
                </div>
                  <span className="text-gray-300">Multiply your shares (Not Available)</span>
                </button>
          </div>
          </div>
          </div>


          <div className="w-full flex justify-between gap-2 mt-5">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]"
          onClick={() => setOpen(true)}
          >
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex">
                <button className="flex items-center">
                <img src={highVoltage} width={30} height={30} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">300,000</span>
                </div>
                  <span className="text-gray-300">Increase Charging Speed to 15sec</span>
                </button>
          </div>
          </div>
          </div>

          <div className="w-full flex justify-between gap-2 mt-5">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]"
          onClick={() => setOpen2(true)}
          >
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex">
                <button className="flex items-center">
                <img src={battery} width={30} height={30} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">500,000</span>
                </div>
                  <span className="text-gray-300">increase Charge Capacity x2</span>
                </button>
          </div>
          </div>
          </div>

          <div className="w-full flex justify-between gap-2 mt-5">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]"
          onClick={() => setOpen3(true)}
          >
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex">
                <button className="flex items-center">
                <img src={multitap} width={30} height={30} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">500,000</span>
                </div>
                  <span className="text-gray-300">Increase Tap Level x5</span>
                </button>
          </div>
          </div>
          </div>


          <div className="w-full flex justify-between gap-2 mt-5">
          <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-1 px-2 rounded-2xl flex">
                <button className="flex items-center">
                <img src={tapbot} width={30} height={30} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-center">
                <img src={coin} width={20} height={20} />
                <span className="ml-2">2,500,000</span>
                </div>
                  <span className="text-gray-300">Tap Bot (Not Available)</span>
                </button>
          </div>
          </div>
          </div>

          </div>

          {open && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center items-center justify-center bg-[#0000004e]">
                  <div
              className={`w-[75%] 800px:w-[60%] bg-[#000] shadow rounded min-h-[30vh] p-4 items-center text-center relative`}>
                <div className="w-full flex flex-col">
                <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        className="cursor-pointer absolute top-3 right-3 text-[#fff]"
                        onClick={() => setOpen(false)}
                      />
                    </div>

                  <span className="m-4 text-2xl font-bold"><img src={coin} width={20} height={20} /> 300,000</span>       
                             
  
                  <p className="text-center !text-[16px] !font-[300]">You're about to pay the above tokens to purchase this boost: 
                  Increase Charging Speed to 15 Seconds
                  </p>
                  
                <div className="items-center text-center text-2xl mt-6 cursor-pointer">
                <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
                onClick={boostHandler}
                >
                  <h3>
                  Confirm
                 </h3>
                </div>
                </div> 
                </div>  
              </div>
              </div>
            )}


            {open2 && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center items-center justify-center bg-[#0000004e]">
                  <div
              className={`w-[75%] 800px:w-[60%] bg-[#000] shadow rounded min-h-[30vh] p-4 items-center text-center relative`}>
                <div className="w-full flex flex-col">
                <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        className="cursor-pointer absolute top-3 right-3 text-[#fff]"
                        onClick={() => setOpen2(false)}
                      />
                    </div>

                  <span className="m-4 text-2xl font-bold"><img src={coin} width={20} height={20} /> 500,000</span>       
                             
  
                  <p className="text-center !text-[16px] !font-[300]">You're about to pay the above tokens to purchase this boost: 
                  increase Charge Capacity x2
                  </p>
                  
                <div className="items-center text-center text-2xl mt-6">
                <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
                onClick={boostHandler2}
                >
                  <h3>
                  Confirm
                 </h3>
                </div>
                </div> 
                </div>  
              </div>
              </div>
            )}


            {open3 && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center items-center justify-center bg-[#0000004e]">
                  <div
              className={`w-[75%] 800px:w-[60%] bg-[#000] shadow rounded min-h-[30vh] p-4 items-center text-center relative`}>
                <div className="w-full flex flex-col">
                <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        className="cursor-pointer absolute top-3 right-3 text-[#fff]"
                        onClick={() => setOpen3(false)}
                      />
                    </div>

                  <span className="m-4 text-2xl font-bold"><img src={coin} width={20} height={20} /> 500,000</span>       
                             
  
                  <p className="text-center !text-[16px] !font-[300]">You're about to pay the above tokens to purchase this boost: 
                  Increase Tap Level x5
                  </p>
                  
                <div className="items-center text-center text-2xl mt-6">
                <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
                onClick={boostHandler3}
                >
                  <h3>
                  Confirm
                 </h3>
                </div>
                </div> 
                </div>  
              </div>
              </div>
            )}

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-gray-300">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-[#c89b2a] font-[500]">Boosts</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats"><button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
        
    )
};


const MinerBoostsPage = () => {
    return (
        <>
        <MinerBoosts />
        </>
    )
}

export default MinerBoostsPage;
