import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import styles from "../styles/styles";
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import stats from '../Assests/eTSTImg/stats.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import refs from '../Assests/eTSTImg/refs.png';
import logout2 from '../Assests/eTSTImg/logout2.png';
import { useSelector } from "react-redux";


const MinerFriends = () => {

    const { minertasks } = useSelector((state) => state.minertask);  
    const { rminer } = useSelector((state) => state.miner);        
        
    console.log(rminer);  

    const refLink = rminer && rminer?.refLink;

    const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
    };   
    
    
    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #35063E, rgb(132, 82, 129))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
          <div className="mt-3 text-3xl font-bold flex items-center">
            <img src={coin} width={20} height={20} />
            <span className="ml-2">30,000</span>
            </div>
             <div className="text-[12px] mt-4 flex items-center">
            <h5 className="font-[300] text-center">Per Telegram Referrer + Tasks + Taps = More eTST</h5>
            </div>   

            <div className="mt-5 text-3xl font-bold flex items-center">
            <span className="text-center">{rminer?.referralCount === undefined ? "0" : rminer?.referralCount}</span>
            </div>
             <div className="text-base mt-4 flex items-center">
            <h5 className="font-[300]">Total Referrals</h5>
            </div>        

          

          <div className="mt-6 text-3xl font-bold flex items-center">
          <div className={`${styles.buttonRed} !bg-[#a000c8]`} onClick={writeTextToClipboard}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Copy Link
               </span>
          </div>
          </div>
          <div className="text-[12px] mt-1 flex items-center">
            <h5 className="font-[300]">{rminer?.refLink === undefined ? "-" : rminer?.refLink}</h5>
          </div>

          

          <div className="relative mt-4">
            <img src={refs} width={230} height={230} alt="etst" />
            </div>
        </div>

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-gray-300">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-[#c89b2a] font-[500]">Friends</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats">
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
        
    )
};


const MinerFriendsPage = () => {
    return (
        <>
        <MinerFriends />
        </>
    )
}

export default MinerFriendsPage;

