export const server = "https://totlesoftpay-backend.vercel.app/api/v2";

//  http://localhost:8000/api/v2

//grey #F6F6F5
// default youtube video ID kGKQF5krIdM

// our yellow color code :  bg-[#c89b2a]


