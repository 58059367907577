import React, {useState, useEffect} from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../../server";
import etstmain from '../../../Assests/eTSTImg/etstcoin.png';
import etstmain2 from '../../../Assests/eTSTImg/etstcoin2.png';
import coin from '../../../Assests/eTSTImg/coin.png';
import turbo from '../../../Assests/eTSTImg/turbo.png';
import battery from '../../../Assests/eTSTImg/battery.png';
import multitap from '../../../Assests/eTSTImg/multitap.png';
import trophy from '../../../Assests/eTSTImg/trophy.png';
import highVoltage from '../../../Assests/eTSTImg/high-voltage.png';
import bear from '../../../Assests/eTSTImg/bear.png';
import rocket from '../../../Assests/eTSTImg/rocket.png';
import lol from '../../../Assests/eTSTImg/lol.gif';
import tasks from '../../../Assests/eTSTImg/tasks.png';
import stats from '../../../Assests/eTSTImg/stats.png';
import taskson from '../../../Assests/eTSTImg/taskson.png';
import wallet from '../../../Assests/eTSTImg/wallet.png';
import logout from '../../../Assests/eTSTImg/logout.png';
import logout2 from '../../../Assests/eTSTImg/logout2.png';
import './mine.css';
import { useSelector, useDispatch } from "react-redux";
//import { loadMiner2 } from "../../../redux/actions/miner";

import bronze from '../../../Assests/eTSTImg/lbronze.png';
import silver from '../../../Assests/eTSTImg/lsilver.png';
import gold from '../../../Assests/eTSTImg/lgold.png';
import platinum from '../../../Assests/eTSTImg/lplatinum.png';
//import Spinner from '../../Spinner/Spinner';
import styles from "../../../styles/styles";
import Backg2 from '../../../Backg2';


const MinerAccount = () => {

    const { minertasks } = useSelector((state) => state.minertask); 
    const { rminer } = useSelector((state) => state.miner);
    const [pointsToSave, setPointsToSave] = useState(0);
    //const [clicks, setClicks] = useState([]);
    const [energy, setEnergy] = useState(1000);
    const [countdown, setCountdown] = useState(30);
    const [isClaimTime, setIsClaimTime] = useState(false);
    const navigate = useNavigate(); 
    const [isLoading, setIsLoading] = useState(false);
    const [energyToReduce, setEnergyToReduce] = useState(2);
    const [popUp1Open, setPopUp1Open] = useState(false);
    const [popUp2Open, setPopUp2Open] = useState(false);
    const [popUp3Open, setPopUp3Open] = useState(false);
    const [popUp4Open, setPopUp4Open] = useState(false);
    const [clicks, setClicks] = useState([]);
    //const dispatch = useDispatch();
    
    const [message2, setMessage2] = useState(false);
    

    console.log("rminer is"); 
    console.log(rminer); 

    useEffect(() => {
      const bC = rminer && rminer.batteryCapacity;
      if(bC !== undefined){
        setEnergy(bC);
      }

      const cD = rminer && rminer.rechargeSpeed;
      if(cD !== undefined){
        setCountdown(cD);
      }

      const tS = rminer && rminer.tappingSpeed;
      if(tS !== undefined){
        setEnergyToReduce(tS);
      }
    }, []);

    
    useEffect(() => {
      const timer =
      countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(timer);
     
      }, [countdown]);

    useEffect(() => {
      if(countdown === 0){
        setTimeout(() => {
          setIsClaimTime(true);
        }, 2000);
      }
    }, [countdown]);


    // useEffect hook to restore energy over time
  useEffect(() => {
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => Math.min(prevEnergy + 1, 1000));
    }, 500); // Restore 10 energy points every 5 second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);


    useEffect(() => {
      const minerReachedNewLevel = rminer && rminer.totalTokenCollected;
      const merited = rminer && rminer.levelclamed;
  
      if(rminer && minerReachedNewLevel > 999999 && merited < 2){
        setTimeout(() => {
          setPopUp1Open(true);
        }, 10000)
      }

      if(rminer && minerReachedNewLevel > 4999999 && merited < 3){
        setTimeout(() => {
          setPopUp2Open(true);
        }, 10000)
      }

      if(rminer && minerReachedNewLevel > 9999999 && merited < 4){
        setTimeout(() => {
          setPopUp3Open(true);
        }, 10000)
      }

      if(rminer && minerReachedNewLevel > 19999999 && merited < 5){
        setTimeout(() => {
          setPopUp4Open(true);
        }, 10000)
      }
    }, []);

    useEffect(() => {
      setTimeout(() => {
        setMessage2(true);
        }, 15000);
     }, []);

     
     const handleClicks = (e) => {
      e.preventDefault(); // Prevent default behavior for touch events
  
      // Get the touch points
      const touchPoints = e.touches; // Use e.changedTouches for touchend events
      const newClicks = [];
  
      for (let i = 0; i < touchPoints.length; i++) {
          const rect = e.currentTarget.getBoundingClientRect();
          const x = touchPoints[i].clientX - rect.left;
          const y = touchPoints[i].clientY - rect.top;
  
          if (energy > 0 && isClaimTime) {
              setEnergy((prevEnergy) => prevEnergy - energyToReduce);
              setPointsToSave((prevPoints) => prevPoints + energyToReduce);
              newClicks.push({ id: Date.now() + i, x, y }); // Unique ID for each tap
          }
      }
  
      if (newClicks.length > 0) {
          setClicks((prevClicks) => [...prevClicks, ...newClicks]);
      }
  };
  
  const handleAnimationEnd = (id) => {
      setClicks((prevClicks) => prevClicks.filter(click => click.id !== id));
  };

    
    const pointsUpdateHandler = () => {
      
      etstUpdateHandler();
      
    }

   const logoutHandler = () => {
        axios
          .get(`${server}/miner/logout-miner`, { withCredentials: true })
          .then((res) => {
            navigate("/mine");
            window.location.reload(true);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      };

      const newTokenCollected = pointsToSave;

      console.log(newTokenCollected);

      const etstUpdateHandler = async () => {
        setIsLoading(true);    
        const id = rminer?._id;
        
        await axios
        
              .put(
                `${server}/miner/update-miner-tokens/${id}`,
                {
                    newTokenCollected: newTokenCollected,
                },
                { withCredentials: true }
              )
              .then((res) => {
                toast.success("Coins Claimed!");
                setIsLoading(false);
                window.location.reload();
              })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level2Handler = async () => {        
    
        const id = rminer?._id;

        const newLevel = 2;
        const newLLevel = 2;
        const newlBonus = 1000000;
        const levelclamed = 2;

        setIsLoading(true);
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                    newlBonus: newlBonus,
                    levelclamed: levelclamed,
                },
                { withCredentials: true }
              )
              .then((res) => {
                setIsLoading(false);
                toast.success("Bonus Claimed!");
                navigate("/app.tst-token/miner/earn");
                window.location.reload();
                })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }



      const level3Handler = async () => {        
    
        const id = rminer?._id;

        const newLevel = 3;
        const newLLevel = 3;
        const newlBonus = 2000000;
        const levelclamed = 3;

        setIsLoading(true);
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                    newlBonus: newlBonus,
                    levelclamed: levelclamed,
                },
                { withCredentials: true }
              )
              .then((res) => {
                setIsLoading(false);
                toast.success("Bonus Claimed!");
                navigate("/app.tst-token/miner/earn");
                window.location.reload();
                })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level4Handler = async () => {        
    
        const id = rminer?._id;

        const newLevel = 4;
        const newLLevel = 4;
        const newlBonus = 3000000;
        const levelclamed = 4;

        setIsLoading(true);
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                    newlBonus: newlBonus,
                    levelclamed: levelclamed,
                },
                { withCredentials: true }
              )
              .then((res) => {
                setIsLoading(false);
                toast.success("Bonus Claimed!");
                navigate("/app.tst-token/miner/earn");
                window.location.reload();
                })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level5Handler = async () => {        
    
        const id = rminer?._id;

        const newLevel = 5;
        const newLLevel = 5;
        const newlBonus = 5000000;
        const levelclamed = 5;

        setIsLoading(true);
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                    newlBonus: newlBonus,
                    levelclamed: levelclamed,
                },
                { withCredentials: true }
              )
              .then((res) => {
                setIsLoading(false);
                toast.success("Bonus Claimed!");
                navigate("/app.tst-token/miner/earn");
                window.location.reload();
                })
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      

    return (
        <>
          {isLoading ? (
            <div className="min-h-screen w-full relative flex flex-col justify-center"
            style={{ background: "linear-gradient(#000, #35063E, rgb(132, 82, 129))" }}>
            <div className="mt-2 sm:mx-auto sm:w-[80%] sm:max-w-md p-4">
              <div className="py-4 px-4">
                <div>
                  <button className="group relative w-full flex justify-center">
                    <img src={lol} width='200px' alt="" className="mr-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
              <div className="w-full flex justify-between gap-2 mt-4">
                <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
                  <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
                    <button className="flex flex-col items-center gap-1">
                      <img src={coin} width={24} height={24} alt="" />
                      {message2 ? <h5 className="text-[#fff]">...wait a little or <span onClick={logoutHandler} className="text-[yellow]">Reload</span></h5> : <span className="text-[#fff]">eTST...loading Lots of Love</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) : (
            <div
            className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
            style={{
            background:
             "linear-gradient(#000, #35063E, rgb(132, 82, 129))",
            }}>
              <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
    
              <div className="fixed top-0 left-0 w-full px-4 pt-3 z-10 flex flex-col items-center text-white">
              <div className="w-full flex justify-between gap-2 mt-1">
               <div className="flex-grow flex items-center max-w-90 text-sm font-[400]">
                  <div className="w-full bg-[#000] py-4 rounded-2xl flex justify-around">
                  <button className="flex flex-col items-center gap-1">
                      <span className="text-[#c89b2a] font-[400]">Miner</span>
                      <span className="text-gray-400">@{rminer?.username === undefined ? "-" : rminer?.username} Lv{rminer?.boostLevel === undefined ? "-" : rminer?.boostLevel}</span>
                    </button>
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                  <button className="flex flex-col items-center gap-1">
                  <span className="text-[#c89b2a] font-[400]">eTST</span>
                  <span className="text-gray-400">{rminer?.totalTokenCollected === undefined ? "-" : (rminer?.totalTokenCollected).toLocaleString()}</span>
                  </button>
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                    {isClaimTime ? (
                      <>
                    {rminer === undefined ? (
                      <button className="flex flex-col items-center gap-1 cursor-pointer" onClick={logoutHandler}>
                        <img src={logout} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]">Enter mine</span>
                      </button>
                    ) : (
                      <button className="flex flex-col items-center gap-1 cursor-pointer" onClick={pointsUpdateHandler}>
                        <img src={etstmain} width={24} height={24} alt="etst" /> 
                        <span className="text-[#c89b2a]">Claim Now</span>
                      </button>
                    )}
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                    {rminer === undefined ? (
                      <button className="flex flex-col items-center gap-1 cursor-pointer" onClick={logoutHandler}>
                        <img src={logout2} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]">offline</span> 
                      </button>
                    ) : (
                      <button className="flex flex-col items-center gap-1 cursor-pointer">
                        <img src={logout} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]" onClick={logoutHandler}>logout</span>
                      </button>
                    )}
                      </>
                    ) : (
                      <>
                    {rminer === undefined ? (
                      <button className="flex flex-col items-center gap-1 cursor-pointer" onClick={logoutHandler}>
                        <img src={logout} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]">Enter mine</span> 
                      </button>
                    ) : (
                      <button className="flex flex-col items-center gap-1 cursor-pointer">
                        <span className="text-[#c89b2a]">Claim in</span> 
                        <span className="text-[22px] font-[600]">{countdown} sec</span> 
                      </button>
                    )}
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                    {rminer === undefined ? (
                      <button className="flex flex-col items-center gap-1 cursor-pointer"  onClick={logoutHandler}>
                        <img src={logout2} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]">offline</span> 
                      </button>
                    ) : (
                      <button className="flex flex-col items-center gap-1 cursor-pointer">
                        <img src={logout} width={24} height={24} alt="Friends" />
                        <span className="text-gray-300 font-[300]" onClick={logoutHandler}>logout</span>
                      </button>
                    )}
                      </>
                    )}
                
                </div>
                </div>
              </div>
              <div className="mt-3 text-5xl font-bold flex items-center">
                <img src={coin} width={44} height={44} />
                {rminer === undefined ? <span className="ml-2 !text-[18px] font-[400]">Updating...</span> : <span className="ml-2">{pointsToSave.toLocaleString()}</span>}
              </div>
              <Link to="/app.tst-token/miner/league">
              <div className="text-base mt-3 flex items-center">
              {rminer?.leagueLevel === undefined ? "-" : (
                      <>
                    {rminer?.leagueLevel === 1 ? <img src={trophy} width={25} height={25} alt="" /> : null}
                    {rminer?.leagueLevel === 2 ? <img src={bronze} width={25} height={25} alt="" /> : null}
                    {rminer?.leagueLevel === 3 ? <img src={silver} width={25} height={25} alt="" /> : null}
                    {rminer?.leagueLevel === 4 ? <img src={gold} width={25} height={25} alt="" /> : null}
                    {rminer?.leagueLevel === 5 ? <img src={platinum} width={25} height={25} alt="" /> : null}
                      </>
                    )}
    
                {rminer?.leagueLevel === undefined ? "-" : (
                    <>
                    {rminer?.leagueLevel === 1 ? <span className="ml-1 font-[400]">Starter <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                    {rminer?.leagueLevel === 2 ? <span className="ml-1 font-[400]">Bronze <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                    {rminer?.leagueLevel === 3 ? <span className="ml-1 font-[400]">Silver <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                    {rminer?.leagueLevel === 4 ? <span className="ml-1 font-[400]">Gold <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                    {rminer?.leagueLevel === 5 ? <span className="ml-1 font-[400]">Platinum <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                    </>
                )}    
                
              </div>
              </Link>
            </div>
    
            
            <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
              <Link to='/app.tst-token/mine/guide'>
              <h5 className="w-[60px] flex text-[13px] text-[#fff] font-[500] cursor-pointer mr-2 bg-[#000] bg-opacity-70 py-2 px-2 rounded-2xl justify-center">Guide</h5>
              </Link>
              
            <div className="w-full flex justify-between gap-2 mt-2">
              <div className="w-[120px] flex items-center justify-start max-w-20">
                  <div className="flex items-center justify-center">
                    <img src={highVoltage} width={44} height={44} alt="High Voltage" />
                    {rminer === undefined ? "-" : (
                      <div className="ml-2 text-left">
                      <span className="text-white text-2xl font-bold block">{energy}</span>
                      <span className="text-white text-large opacity-75">/ 1000</span>
                    </div>
                    )}
                  </div>
                </div>
  
               <div className="flex-grow flex items-center max-w-90 text-[10px] font-[300]">
                  <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
                  <button className="flex flex-col items-center gap-1">
                      <img src={coin} width={20} height={20} alt="Earn" />
                      <span className="text-[#c89b2a] font-[500]">Earn</span>
                    </button>
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                    <Link to="/app.tst-token/miner/tasks">
                    <button className="flex flex-col items-center gap-1">
                    {minertasks && minertasks.length > 0 ? <img src={taskson} width={20} height={20} alt="Tasks" /> : <img src={tasks} width={18} height={18} alt="Tasks" />}
                      <span className="text-gray-300">Tasks</span>
                    </button>
                    </Link>
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                    <Link to="/app.tst-token/miner/boost">
                    <button className="flex flex-col items-center gap-1">
                      <img src={turbo} width={20} height={20} alt="Boosts" />
                      <span className="text-gray-300">Boosts</span>
                    </button>
                    </Link>
                    <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                    <Link to="/app.tst-token/miner/stats">
                    <button className="flex flex-col items-center gap-1">
                      <img src={stats} width={20} height={20} alt="Stats" />
                      <span className="text-gray-300">Stats</span>
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
  
  
            <div className="w-full bg-[#000] bg-opacity-70 rounded-full mt-2">
              <div className="bg-gradient-to-r from-[#f3c45a] to-[#fffad0] h-4 rounded-full" style={{ width: `${(energy / 1000) * 100}%` }}></div>
            </div>
            </div>
    
<div className="flex-grow flex items-center justify-center">
    <div className="relative mt-4" onTouchStart={handleClicks}>
        {isClaimTime ? (
            <img src={etstmain2} width={220} height={220} alt="etst" />
        ) : (
            <img src={lol} width='200px' alt="" className="mr-2" />
        )}
        {clicks.map((click) => (
            <div
                key={click.id}
                className="absolute text-3xl font-bold opacity-0"
                style={{
                    top: `${click.y - 120}px`,
                    left: `${click.x - 80}px`,
                    animation: `float 1s ease-out`,
                }}
                onAnimationEnd={() => handleAnimationEnd(click.id)}
            >
                <img src={etstmain2} width={30} height={30} alt="24" />
                <img src={etstmain2} width={30} height={30} alt="24" />
                +2
            </div>
        ))}
    </div>
</div>
  
  
  
  
  
  
            {popUp1Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={level2Handler}>
                      <div>
                      <br />
                      <h4 className="text-[25px] font-[600] text-[#000]">Congratulations {rminer?.telegramID}!</h4>
                      <h4 className="text-[16px] text-[#000]">Claime Level 2 Bonus</h4>
                      <br />
  
            <div className="w-full flex justify-between gap-2 mt-4">
            <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
            <div className={`w-full bg-[#A67C00] py-1 px-2 rounded-2xl flex`}>
                  <button className="flex items-center">
                  <img src={bronze} width={50} height={50} alt="" />
                  </button>
                  <button className="flex flex-col items-center gap-1 ml-2">
                  <div className="mt-3 text-2xl font-bold flex items-center">
                  <img src={coin} width={20} height={20} />
                  <span className="ml-2">1,000,000</span>
                  </div>
                    <span className="text-gray-300">Claim Your Bonus Now!</span>
                  </button>
            </div>
            </div>
            </div>
          <br />
         
                       <button
                          className={`${styles.button} !h-[42px] text-white`}
                          type="submit"
                        >
                          Claim
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !h-[42px] text-white mt-5`}
                          onClick={() => setPopUp1Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}
  
  
  
  {popUp2Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={level3Handler}>
                      <div>
                      <br />
                      <h4 className="text-[25px] font-[600] text-[#000]">Congratulations {rminer?.telegramID}!</h4>
                      <h4 className="text-[16px] text-[#000]">Claime Level 3 Bonus</h4>
                      <br />
  
            <div className="w-full flex justify-between gap-2 mt-4">
            <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
            <div className={`w-full bg-[#A67C00] py-1 px-2 rounded-2xl flex`}>
                  <button className="flex items-center">
                  <img src={bronze} width={50} height={50} alt="" />
                  </button>
                  <button className="flex flex-col items-center gap-1 ml-2">
                  <div className="mt-3 text-2xl font-bold flex items-center">
                  <img src={coin} width={20} height={20} />
                  <span className="ml-2">2,000,000</span>
                  </div>
                    <span className="text-gray-300">Claim Your Bonus Now!</span>
                  </button>
            </div>
            </div>
            </div>
          <br />
         
                       <button
                          className={`${styles.button} !h-[42px] text-white`}
                          type="submit"
                        >
                          Claim
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !h-[42px] text-white mt-5`}
                          onClick={() => setPopUp2Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}
  
  
  
  {popUp3Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={level4Handler}>
                      <div>
                      <br />
                      <h4 className="text-[25px] font-[600] text-[#000]">Congratulations {rminer?.telegramID}!</h4>
                      <h4 className="text-[16px] text-[#000]">Claime Level 4 Bonus</h4>
                      <br />
  
            <div className="w-full flex justify-between gap-2 mt-4">
            <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
            <div className={`w-full bg-[#A67C00] py-1 px-2 rounded-2xl flex`}>
                  <button className="flex items-center">
                  <img src={bronze} width={50} height={50} alt="" />
                  </button>
                  <button className="flex flex-col items-center gap-1 ml-2">
                  <div className="mt-3 text-2xl font-bold flex items-center">
                  <img src={coin} width={20} height={20} />
                  <span className="ml-2">3,000,000</span>
                  </div>
                    <span className="text-gray-300">Claim Your Bonus Now!</span>
                  </button>
            </div>
            </div>
            </div>
          <br />
         
                       <button
                          className={`${styles.button} !h-[42px] text-white`}
                          type="submit"
                        >
                          Claim
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !h-[42px] text-white mt-5`}
                          onClick={() => setPopUp3Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}
  
  
  {popUp4Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
              <form onSubmit={level5Handler}>
                      <div>
                      <br />
                      <h4 className="text-[25px] font-[600] text-[#000]">Congratulations {rminer?.telegramID}!</h4>
                      <h4 className="text-[16px] text-[#000]">Claime Level 5 Bonus</h4>
                      <br />
  
            <div className="w-full flex justify-between gap-2 mt-4">
            <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
            <div className={`w-full bg-[#A67C00] py-1 px-2 rounded-2xl flex`}>
                  <button className="flex items-center">
                  <img src={bronze} width={50} height={50} alt="" />
                  </button>
                  <button className="flex flex-col items-center gap-1 ml-2">
                  <div className="mt-3 text-2xl font-bold flex items-center">
                  <img src={coin} width={20} height={20} />
                  <span className="ml-2">5,000,000</span>
                  </div>
                    <span className="text-gray-300">Claim Your Bonus Now!</span>
                  </button>
            </div>
            </div>
            </div>
          <br />
         
                       <button
                          className={`${styles.button} !h-[42px] text-white`}
                          type="submit"
                        >
                          Claim
                        </button>
                      </div>
                      <button
                          className={`${styles.button} !h-[42px] text-white mt-5`}
                          onClick={() => setPopUp4Open(false)}
                         >
                         Cancle
                        </button>
                      </form>
            </div>
            
          </div>
        )}

          <Backg2 />    
           </div>
          </div>
          )}
          </>
    )
};

export default MinerAccount;
