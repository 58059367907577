import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import stats from '../Assests/eTSTImg/stats.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { isVisible } from "@testing-library/user-event/dist/utils";


const MineWhitePaper = () => {

        
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #35063E, rgb(132, 82, 129))",
        }}>


<div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <br />
        <br />
      <h1
          className={`!text-[30px] leading-[1.2] 800px:text-[60px] text-[#fff] font-[600]`}
        >
          White Paper for eTST Token on the TON Blockchain
        </h1> 
        <h2 className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#fff]">Abstract</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST is going to be the governance token for our ecosystem. It aims to revolutionize the digital economy by providing a secure, efficient, and decentralized solution for transactions and smart contracts on the TON (The Open Network) blockchain. This document outlines the vision, technology, use cases, governance, and roadmap for eTST, establishing it as a pivotal asset in the burgeoning decentralized finance (DeFi) ecosystem.</h2>
        <br />
        <hr />
        <br />

        <h2 className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#fff]">Introduction</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        The digital economy is evolving rapidly, with blockchain technology at its core. As decentralized systems gain traction, the need for versatile and efficient tokens becomes increasingly evident. The TON blockchain, developed by the Telegram team, offers a high-performance environment ideal for deploying smart contracts and executing transactions at scale.</h2>
        <br />

        <h2 className="pt-5 text-[16px] font-[Poppins] font-[600] text-[#fff]">Vision</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST aims to create a seamless experience for users, developers, and businesses, enabling them to transact securely while accessing a wide range of decentralized applications (dApps) and services. By leveraging the TON blockchain's capabilities, eTST seeks to facilitate a new standard for digital asset transactions.</h2>
        <br />
        
        <h2 className="pt-5 text-[25px] font-[Poppins] font-[600] text-[#fff]">Technology Overview</h2>
        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">The TON Blockchain</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        The TON blockchain is a Layer 1 solution designed for speed, scalability, and security. Key features include:<br />
        * High Throughput: Capable of processing millions of transactions per second.<br />
        * Low Latency: Instant finality ensures rapid confirmation of transactions.<br />
        * User-Friendly Interface: Intuitive integration with existing platforms.</h2>
        <br />
        
        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">eTST Specifications</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        * Token Name: eTS-Token<br />
        * Token Symbol: eTST <br />
        * Blockchain: TON <br />
        * Total Supply: 500,000,000,000 eTST <br />
        * Token Standard: TON ERC-20 compatible</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Smart Contracts</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST will utilize smart contracts to facilitate a variety of applications, including:<br />
        * Decentralized Finance (DeFi): Lending, borrowing, and yield farming.<br />
        * Token Swaps: Enabling users to exchange assets seamlessly.<br />
        * NFTs and Gaming: Supporting tokenized assets within gaming ecosystems.</h2>
        <br />
        
        <h2 className="pt-5 text-[25px] font-[Poppins] font-[600] text-[#fff]">Use Cases</h2>
        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">1. Decentralized Finance</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST will serve as the native currency for DeFi applications, allowing users to lend, borrow, and stake tokens. This provides liquidity to the ecosystem while enabling passive income generation.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">2. E-commerce</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        Businesses can integrate eTST for secure and cost-effective transactions, reducing reliance on traditional payment gateways and enhancing customer trust through transparency.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">3. Governance</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        Token holders will participate in governance decisions, influencing protocol upgrades, fund allocation, and community initiatives. This ensures that the eTST ecosystem remains aligned with user needs.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">4. Cross-Chain Interoperability</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST will be designed to facilitate interactions with other blockchains, broadening its use cases and enhancing liquidity across different platforms.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Governance Model</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        The governance of eTST will be community-driven, utilizing a decentralized autonomous organization (DAO) framework. Token holders will propose and vote on significant decisions, including: <br />
        * Proposals for new features or applications.<br />
        * Budget allocations for ecosystem development.<br />
        * Adjustments to the tokenomics of eTST.</h2>
        <br />


        <h2 className="pt-5 text-[25px] font-[Poppins] font-[600] text-[#fff]">Roadmap</h2>
        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Phase 1: Token Launch</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        * Complete smart contract development and auditing.<br />
        * Launch Tap-To-Earn (TTE) Telegram Application by rewarding miners with eTST to raise awareness and adoption of Token.<br />
        * Snapshot and Distribution of TST to eTST Share holders in ratio to the amount of shares they have.<br />
        * DEX listing.<br />
        * Establish partnerships with key players in the DeFi and e-commerce sectors.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Phase 2: Ecosystem Development</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        * Launch DeFi applications, including lending and staking platforms.<br />
        * Develop partnerships with merchants to enable eTST acceptance in e-commerce.<br />
        * Initiate community engagement initiatives to foster growth.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Phase 3: Expansion and Interoperability</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        * Implement cross-chain functionalities for eTST. <br />
        * Explore integration with additional blockchain networks.<br />
        * Expand the ecosystem through strategic partnerships and collaborations.<br />
        * Migrating to the eTST Blockchain</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Conclusion</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST is poised to be a transformative force in the digital economy, harnessing the power of the TON blockchain to create a versatile and efficient token. With a focus on security, user experience, and community governance, eTST aims to establish itself as a foundational asset within the decentralized landscape.</h2>
        <br />

        <h2 className="pt-2 text-[16px] font-[Poppins] font-[600] text-[#fff]">Call to Action</h2>
        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        We invite developers, investors, and enthusiasts to join us in realizing the vision of eTST. Together, we can shape the future of decentralized finance and empower individuals and businesses worldwide.</h2>
        <br />

        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        DISCLAIMER: ...investing in crypto is risky.</h2>

        <br />
        <hr />
        <br />

        <button className="flex items-center">
        <img src={coin} width={30} height={30} alt="" />
        </button>        

        <h2 className="pt-1 text-[16px] font-[Poppins] font-[300] text-[#fff]">
        eTST... endless possibilities.</h2>

             
        
        
        <Link to="/app.tst-token/miner/earn" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                    Enter Mine
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <br />
      </div>
          
      </div>
        
    )
};


const MineWhitePaperPage = () => {
    return (
        <>
        <MineWhitePaper />
        </>
    )
}

export default MineWhitePaperPage;
