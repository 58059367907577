import React from "react";
import coin from '../Assests/eTSTImg/coin.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import stats from '../Assests/eTSTImg/stats.png';
import refs from '../Assests/eTSTImg/refs.png';
import logout2 from '../Assests/eTSTImg/logout2.png';
import styles from "../styles/styles";
import { Link } from "react-router-dom";



const MGuide2 = () => {

    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #35063E, rgb(132, 82, 129))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full h-screen px-6 pt-6 z-10 flex flex-col items-center text-white overflow-y-scroll">
          <div className="mt-3 text-3xl font-bold flex items-center">
            <img src={coin} width={20} height={20} />
            <span className="ml-2">30,000</span>
            </div>
             <div className="text-base mt-4 flex items-center">
            <h5 className="font-[300]">Per Telegram Referrer + Taps + Tasks = More eTST</h5>
            </div>

            <div className="text-base mt-4 flex items-center">
          <img src={multitap} width={20} height={20} />
            <h5 className="font-[300]">This is a web-mining-game, it should be open on a web browser and not on telegram.</h5>
          </div>
           

          <div className="text-base mt-4 flex items-center">
          <img src={multitap} width={20} height={20} />
            <h5 className="font-[300]">You can only access the mine using your generated Telegram Miner Name that you got when you joined. Onlike other Tap-2-Earn game, you can access the mine with that name from multiple devices e.g. Mobile, Desktop, Tablet etc. while tapping on the go. </h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={multitap} width={20} height={20} />
            <h5 className="font-[300]">You can ONLY tap/mine after the timer has stoped and the eTST coin has appeared.</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={coin} width={20} height={20} />
            <h5 className="font-[300] ml-2">You MUST click "Claim Now" button at the top after tapping/mining enough shares to save your tokens before leaving the page else your taps/shears for that section will be lost and you will need to wait for the timer to stop before you can start mining again.</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={coin} width={20} height={20} />
            <h5 className="font-[300] ml-2">If you click the "Claim Now" button without first tapping/mining, you will receive zero (0) shares for that tapping section. Ensure you tap/mine before clickig the 'claim now' button.</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
            <img src={logout2} width={20} height={20} />
            <h5 className="font-[300] ml-2">if you're offline it means your session has expired and you will need to re-enter mine to be able to save your tapped tokens.</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={stats} width={20} height={20} />
            <h5 className="font-[300] ml-2">Your total shares of eTST (referral bonus, tasks bonus and taps) will be calculated and credited accordingly during token distribution</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={rocket} width={20} height={20} />
            <h5 className="font-[300] ml-2">Enter mine from Chrome, Edge, FireFox or Opera browser for better tapping/mining experience</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={bear} width={20} height={20} />
            <h5 className="font-[300] ml-2">Ensure to follow all rules accordingly, and be active on all our socials/channels as all credible and active miners will be rewarded.</h5>
          </div>

          <div className="text-base mt-4 flex items-center">
          <img src={coin} width={20} height={20} />
            <h5 className="font-[300] ml-2">eTST... Lots of Love (lol)</h5>
          </div>
          
          

          <div className="relative mt-4 mb-9">
            <img src={refs} width={30} height={30} alt="etst" />
            </div>
            <br />
            <br />
            <br />
            <h5 className="mb-9">-</h5>
        </div>
        <br />
        <br />
        <br />        
        

        </div>
      </div>
        
    )
};


const MGuide2Page = () => {
    return (
        <>
        <MGuide2 />
        </>
    )
}

export default MGuide2Page;


/*
<div className="mt-4 text-3xl font-bold flex items-center">
          <Link to="/app.tst-token/miner/earn">
          <div className={`${styles.buttonRed} !bg-[teal]`}>
               <span className="text-[#fff] font-[Poppins] font-[400] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Enter Mine
               </span>
          </div>
          </Link>
          </div>

          */