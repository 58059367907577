import React, {useState, useEffect} from "react";
import { AiOutlineLogin, AiOutlineArrowRight } from "react-icons/ai";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import highVoltage from '../Assests/eTSTImg/high-voltage.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import stats from '../Assests/eTSTImg/stats.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import wallet from '../Assests/eTSTImg/wallet.png';
import logout from '../Assests/eTSTImg/logout.png';
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";


const MinerTaskDetail = () => {

    const { minertasks } = useSelector((state) => state.minertask);  
    const { rminer } = useSelector((state) => state.miner);
    const { id } = useParams();
           
    const [ctoCompare, setCtoCompare] = useState("")
    const [open, setOpen] = useState(false);

    const [isClaimTime, setIsClaimTime] = useState(false);
    const [taskaboutToStart, setTaskaboutToStart] = useState(false);
    const [taskIsStarted, setTaskIsStarted] = useState(false);
    const [bonusClaimed, setBonusClaimed] = useState(false);
    const navigate = useNavigate();  
    const [hasCompleteTask, setHasCompleteTask] = useState(false);
    const [timer, setTimer] = useState(false);


    useEffect(() => {
      if(timer === true){
        setTimeout(() => {
          setHasCompleteTask(true);
        }, 60000);
      }
    }, [timer]);

        
    const dataTU = minertasks && minertasks.find((ita) => ita._id === id);

    const userDoingTask = rminer?._id;

    const isDoneTaskAlready =  dataTU.taskers.some((item) => item['taskerID'] === userDoingTask );


    const trueCode = dataTU.codeTC;
    const taskClass = dataTU.taskClass;
    
    const taskBonusHandler = async () => {
      const refBonusToBeCredited = dataTU.taskReward;
      const refToBeCreditedId = rminer._id;

      if(isDoneTaskAlready){
        return toast.error("You've completed same task earlier");
      }
    
      await axios
      
            .put(
              `${server}/miner/taskbonus/${refToBeCreditedId}`,
              {
                refBonusToBeCredited: refBonusToBeCredited,
              },
              { withCredentials: true }
            )
            .then((res) => {
              setOpen(false);
              setBonusClaimed(true);
              toast.success("Task Bonus Claimed!")
              taskersHandler();
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });    
      }

      const codeCheckerHandler = () => {
        if(ctoCompare !== trueCode){
          return toast.error("Wrong Code!");
        } 

        if(hasCompleteTask === false){
          return toast.error("Task Not Completed!");;
        }
        
        setIsClaimTime(true);
      

      }


      const codeCheckerHandler2 = () => {
        if(hasCompleteTask === false){
          return toast.error("Task Not Completed!");;
        }
        
        setIsClaimTime(true);
      

      }


      const taskersHandler = async () => {
       
        const taskerID = rminer?._id;

        const tId = id;
                
        await axios
        
              .put(
                `${server}/minertask/update-taskers/${tId}`,
                {
                  taskerID : taskerID,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Task Approved!");
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const done = () => {
        navigate("/app.tst-token/miner/tasks");
        window.location.reload();
      }

      
      
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white pt-6">

          {dataTU === undefined ? null : (
            <div className="w-full">

            <div className="flex items-center text-sm font-[400] mt-4">
            <div className="w-full bg-[#A67C00] bg-opacity-10 py-2 px-4 rounded-2xl flex">
                  <div>
                      <h1 className="text-2xl font-bold flex items-start">{dataTU.taskTitle === undefined ? "-" : dataTU.taskTitle}</h1>
                      <p className="items-start">
                      {dataTU.taskDetails === undefined ? "-" : dataTU.taskDetails}
                      </p>
                  </div>
            </div>
            </div>
  
            <div className="flex items-center text-sm font-[300] mt-6">
            <div className="w-full bg-[#A67C00] bg-opacity-10 py-2 px-4 rounded-2xl flex">
                  <button className="flex items-center">
                  <img src={coin} width={40} height={40} alt="" />
                  </button>
                  <button className="flex flex-col items-start gap-1 ml-4">
                  <div className="mt-3 text-2xl font-bold flex items-start">
                  <img src={tasks} width={20} height={20} />
                  <span className="ml-2">{dataTU.taskReward === undefined ? "-" : dataTU.taskReward}</span>
                  </div>
                    <span className="text-gray-300">{dataTU.taskTitle === undefined ? "-" : dataTU.taskTitle}</span>
                  </button>
            </div>
            </div>  
  
  
            {bonusClaimed ? (
              <>
              <div className="items-center text-center text-2xl font-bold mt-2">
              <div className="w-full bg-[gray] py-4 px-4 rounded-2xl text-center">
                    <h3>
                    Completed!
                   </h3>
              </div>
              </div> 

              <div className="items-center text-center text-1xl font-bold mt-2 cursor-pointer">
            <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
            onClick={done}
            >
                  <h3>
                  Done
                 </h3>
            </div>
            </div> 

            </>
    
            ) : (
              <>
              <div className="items-center text-center text-1xl font-bold mt-2 cursor-pointer">
            <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
            onClick={() => setTaskaboutToStart(true)}
            >
                  <h3>
                  Start task
                 </h3>
            </div>
            </div> 
  
            {taskaboutToStart ? (
              <div className="flex items-center text-sm font-[400] mt-2">
              <div className="w-full bg-[#A67C00] bg-opacity-10 py-2 px-4 gap-4 rounded-2xl flex">
                <div>
              <h1 className="text-1xl font-bold flex items-start">Your task</h1>
              <div className="bg-[#1c1c1c] text-[#fff] w-full mt-2 px-2 py-2 rounded-xl">
              <div className="flex justify-between mb-2">
              <h5 className="text-[16px] font-[400] mb-2 mr-5">{dataTU.taskSubDetails === undefined ? "-" : dataTU.taskSubDetails}</h5>
              <Link to={dataTU.taskURL} target="_blank"><h5 onClick={() => {setTaskIsStarted(true); setTimer(true)}} className="text-sm bg-[#fff] text-[#1c1c1c] max-h-[32px] w-[100px] px-2 py-1 rounded-xl overflow-x-hidden overflow-y-auto text-center">Start</h5></Link>
              </div>
              </div>
               </div>
          </div>
          </div>
            ) : null}
                      
  
            {taskIsStarted ? (
              <>
              {taskClass === "Findcode" ? (
                <div className="flex items-center text-sm font-[400] mt-2">
                <div className="w-full bg-[#000] bg-opacity-30 py-2 px-4 rounded-2xl flex">
                <div className="flex justify-between w-full">
                <input
                        type="text"
                        id="ctoCompare"
                        name="ctoCompare"
                        value={ctoCompare}
                        onChange={(e) => setCtoCompare(e.target.value)}
                        className="text-[#000] text-[16px] block w-full px-3 py-2 border border-gray-300 rounded-md"
                      />
      
                      <div className="items-center text-center text-1xl font-bold ml-5 cursor-pointer">
                          <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center"
                          onClick={codeCheckerHandler}
                          >
                          <h3>Check Code</h3>
                          </div>
                          </div>
                </div>
                </div>
                </div>
              ) : (
                <div className="items-center text-center text-1xl font-bold mt-2 cursor-pointer"
                onClick={codeCheckerHandler2}
              >
              <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center">
                    <h3>
                    Check
                   </h3>
              </div>
              </div>
              )}
              </>
            ) : null}
  
            {isClaimTime ? (
              <div className="items-center text-center text-1xl font-bold mt-2 cursor-pointer"
              onClick={() => setOpen(true)}
              >
              <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center">
                    <h3>
                    Finish
                   </h3>
              </div>
              </div> 
            ) : null}
              </>
            )}
  
            {open && (
              <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center items-center justify-center bg-[#0000004e]">
                  <div
              className={`w-[75%] 800px:w-[60%] bg-[#000] shadow rounded min-h-[30vh] p-4 items-center text-center relative`}>
                <div className="w-full flex flex-col">
                <div className="w-full flex justify-end p-3">
                      <RxCross1
                        size={30}
                        className="cursor-pointer absolute top-3 right-3 text-[#fff]"
                        onClick={() => setOpen(false)}
                      />
                    </div>

                  <span className="m-4 text-2xl font-bold"><img src={coin} width={20} height={20} />{dataTU.taskReward}</span>       
                             
  
                  <p className="text-center !text-[16px] !font-[300]">Task completion confirmed!</p>
                  
                <div className="items-center text-center text-1xl mt-6">
                <div className="w-full bg-[#A67C00] py-2 px-4 rounded-2xl text-center cursor-pointer"
                onClick={taskBonusHandler}
                >
                  <h3>
                  Claim eTST
                 </h3>
                </div>
                </div> 
                </div>  
              </div>
              </div>
            )}
  
          </div>
          )}

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                  {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-[#c89b2a] font-[500]">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats">
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
        
    )
};


const MinerTaskDetailPage = () => {
    return (
        <>
        <MinerTaskDetail />
        </>
    )
}

export default MinerTaskDetailPage;
  
